// tslint:disable
/**
 * CLIENT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 *
 * @export
 * @enum {string}
 */
export enum ApState {
    RLCSNEW = 'RLCS_NEW',
    RLCSTOAPPROVE = 'RLCS_TO_APPROVE',
    RLCSTOAMEND = 'RLCS_TO_AMEND',
    RLCSINVALID = 'RLCS_INVALID',
    RLCSSAVED = 'RLCS_SAVED',
    APSNEW = 'APS_NEW',
    APSAPPROVED = 'APS_APPROVED',
    APSINVALID = 'APS_INVALID',
    APSREPLACED = 'APS_REPLACED'
}



