"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * ELZA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchApi = exports.SearchApiFactory = exports.SearchApiFp = exports.SearchApiAxiosParamCreator = exports.IoApi = exports.IoApiFactory = exports.IoApiFp = exports.IoApiAxiosParamCreator = exports.FundsApi = exports.FundsApiFactory = exports.FundsApiFp = exports.FundsApiAxiosParamCreator = exports.ExternalsystemsApi = exports.ExternalsystemsApiFactory = exports.ExternalsystemsApiFp = exports.ExternalsystemsApiAxiosParamCreator = exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = exports.DaosApi = exports.DaosApiFactory = exports.DaosApiFp = exports.DaosApiAxiosParamCreator = exports.AdminApi = exports.AdminApiFactory = exports.AdminApiFp = exports.AdminApiAxiosParamCreator = exports.AccesspointsApi = exports.AccesspointsApiFactory = exports.AccesspointsApiFp = exports.AccesspointsApiAxiosParamCreator = exports.SortingOrderEnum = exports.RevisionState = exports.ReplaceType = exports.LogicalFilterAllOfOperationEnum = exports.LogicalFilterOperationEnum = exports.FsItemType = exports.FieldValueFilterAllOfOperationEnum = exports.FieldValueFilterOperationEnum = exports.ExportRequestState = exports.ApStateApproval = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 * States of ApState
 * @export
 * @enum {string}
 */
var ApStateApproval;
(function (ApStateApproval) {
    ApStateApproval["New"] = "NEW";
    ApStateApproval["ToApprove"] = "TO_APPROVE";
    ApStateApproval["Approved"] = "APPROVED";
    ApStateApproval["ToAmend"] = "TO_AMEND";
})(ApStateApproval = exports.ApStateApproval || (exports.ApStateApproval = {}));
/**
 *
 * @export
 * @enum {string}
 */
var ExportRequestState;
(function (ExportRequestState) {
    ExportRequestState["Pending"] = "PENDING";
    ExportRequestState["Preparing"] = "PREPARING";
    ExportRequestState["Finished"] = "FINISHED";
})(ExportRequestState = exports.ExportRequestState || (exports.ExportRequestState = {}));
/**
    * @export
    * @enum {string}
    */
var FieldValueFilterOperationEnum;
(function (FieldValueFilterOperationEnum) {
    FieldValueFilterOperationEnum["Eq"] = "EQ";
    FieldValueFilterOperationEnum["Neq"] = "NEQ";
    FieldValueFilterOperationEnum["Gt"] = "GT";
    FieldValueFilterOperationEnum["Lt"] = "LT";
    FieldValueFilterOperationEnum["Gte"] = "GTE";
    FieldValueFilterOperationEnum["Lte"] = "LTE";
    FieldValueFilterOperationEnum["Startwith"] = "STARTWITH";
    FieldValueFilterOperationEnum["Endwith"] = "ENDWITH";
    FieldValueFilterOperationEnum["Contains"] = "CONTAINS";
    FieldValueFilterOperationEnum["IsNull"] = "IS_NULL";
    FieldValueFilterOperationEnum["NotNull"] = "NOT_NULL";
})(FieldValueFilterOperationEnum = exports.FieldValueFilterOperationEnum || (exports.FieldValueFilterOperationEnum = {}));
/**
    * @export
    * @enum {string}
    */
var FieldValueFilterAllOfOperationEnum;
(function (FieldValueFilterAllOfOperationEnum) {
    FieldValueFilterAllOfOperationEnum["Eq"] = "EQ";
    FieldValueFilterAllOfOperationEnum["Neq"] = "NEQ";
    FieldValueFilterAllOfOperationEnum["Gt"] = "GT";
    FieldValueFilterAllOfOperationEnum["Lt"] = "LT";
    FieldValueFilterAllOfOperationEnum["Gte"] = "GTE";
    FieldValueFilterAllOfOperationEnum["Lte"] = "LTE";
    FieldValueFilterAllOfOperationEnum["Startwith"] = "STARTWITH";
    FieldValueFilterAllOfOperationEnum["Endwith"] = "ENDWITH";
    FieldValueFilterAllOfOperationEnum["Contains"] = "CONTAINS";
    FieldValueFilterAllOfOperationEnum["IsNull"] = "IS_NULL";
    FieldValueFilterAllOfOperationEnum["NotNull"] = "NOT_NULL";
})(FieldValueFilterAllOfOperationEnum = exports.FieldValueFilterAllOfOperationEnum || (exports.FieldValueFilterAllOfOperationEnum = {}));
/**
 * File system item type
 * @export
 * @enum {string}
 */
var FsItemType;
(function (FsItemType) {
    FsItemType["Folder"] = "FOLDER";
    FsItemType["File"] = "FILE";
})(FsItemType = exports.FsItemType || (exports.FsItemType = {}));
/**
    * @export
    * @enum {string}
    */
var LogicalFilterOperationEnum;
(function (LogicalFilterOperationEnum) {
    LogicalFilterOperationEnum["And"] = "AND";
    LogicalFilterOperationEnum["Or"] = "OR";
})(LogicalFilterOperationEnum = exports.LogicalFilterOperationEnum || (exports.LogicalFilterOperationEnum = {}));
/**
    * @export
    * @enum {string}
    */
var LogicalFilterAllOfOperationEnum;
(function (LogicalFilterAllOfOperationEnum) {
    LogicalFilterAllOfOperationEnum["And"] = "AND";
    LogicalFilterAllOfOperationEnum["Or"] = "OR";
})(LogicalFilterAllOfOperationEnum = exports.LogicalFilterAllOfOperationEnum || (exports.LogicalFilterAllOfOperationEnum = {}));
/**
 * Method of replacing ApAccessPoint
 * @export
 * @enum {string}
 */
var ReplaceType;
(function (ReplaceType) {
    ReplaceType["Simple"] = "SIMPLE";
    ReplaceType["CopyAll"] = "COPY_ALL";
})(ReplaceType = exports.ReplaceType || (exports.ReplaceType = {}));
/**
 * States of ApRevState
 * @export
 * @enum {string}
 */
var RevisionState;
(function (RevisionState) {
    RevisionState["Active"] = "ACTIVE";
    RevisionState["ToApprove"] = "TO_APPROVE";
    RevisionState["ToAmend"] = "TO_AMEND";
})(RevisionState = exports.RevisionState || (exports.RevisionState = {}));
/**
    * @export
    * @enum {string}
    */
var SortingOrderEnum;
(function (SortingOrderEnum) {
    SortingOrderEnum["Asc"] = "asc";
    SortingOrderEnum["Desc"] = "desc";
})(SortingOrderEnum = exports.SortingOrderEnum || (exports.SortingOrderEnum = {}));
/**
 * AccesspointsApi - axios parameter creator
 * @export
 */
exports.AccesspointsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Change state of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {ApStateUpdate} apStateUpdate data for change ApState
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointChangeState: function (id, apStateUpdate, apVersion, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('accessPointChangeState', 'id', id);
                    // verify required parameter 'apStateUpdate' is not null or undefined
                    common_1.assertParamExists('accessPointChangeState', 'apStateUpdate', apStateUpdate);
                    localVarPath = "/accesspoint/{id}/state"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (apVersion !== undefined) {
                        localVarQueryParameter['apVersion'] = apVersion;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(apStateUpdate, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Change state of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {RevStateChange} revStateChange data for change state of revision of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointChangeStateRevision: function (id, revStateChange, apVersion, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('accessPointChangeStateRevision', 'id', id);
                    // verify required parameter 'revStateChange' is not null or undefined
                    common_1.assertParamExists('accessPointChangeStateRevision', 'revStateChange', revStateChange);
                    localVarPath = "/accesspoint/{id}/revision/state"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (apVersion !== undefined) {
                        localVarQueryParameter['apVersion'] = apVersion;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(revStateChange, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Invalidation of ApAccessPoint (delete/replace)
         * @param {string} id id of accesspoint
         * @param {DeleteAccessPointDetail} [deleteAccessPointDetail] addition for method of invalidation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeleteAccessPoint: function (id, deleteAccessPointDetail, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('accessPointDeleteAccessPoint', 'id', id);
                    localVarPath = "/accesspoint/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(deleteAccessPointDetail, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Delete part of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeletePart: function (id, partId, apVersion, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('accessPointDeletePart', 'id', id);
                    // verify required parameter 'partId' is not null or undefined
                    common_1.assertParamExists('accessPointDeletePart', 'partId', partId);
                    localVarPath = "/accesspoint/{id}/part/{partId}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)))
                        .replace("{" + "partId" + "}", encodeURIComponent(String(partId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (apVersion !== undefined) {
                        localVarQueryParameter['apVersion'] = apVersion;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Delete part of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of revision
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeleteRevisionPart: function (id, partId, apVersion, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('accessPointDeleteRevisionPart', 'id', id);
                    // verify required parameter 'partId' is not null or undefined
                    common_1.assertParamExists('accessPointDeleteRevisionPart', 'partId', partId);
                    localVarPath = "/accesspoint/{id}/revision/part/{partId}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)))
                        .replace("{" + "partId" + "}", encodeURIComponent(String(partId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (apVersion !== undefined) {
                        localVarQueryParameter['apVersion'] = apVersion;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * List of automatically generated elements of the description
         * @param {string} id id of accesspoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointGetAutoitems: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('accessPointGetAutoitems', 'id', id);
                    localVarPath = "/accesspoint/{id}/autoitems"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Merge ApRevision & ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {ApStateUpdate} apStateUpdate data for change ApState
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointMergeRevision: function (id, apStateUpdate, apVersion, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('accessPointMergeRevision', 'id', id);
                    // verify required parameter 'apStateUpdate' is not null or undefined
                    common_1.assertParamExists('accessPointMergeRevision', 'apStateUpdate', apStateUpdate);
                    localVarPath = "/accesspoint/{id}/revision/merge"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (apVersion !== undefined) {
                        localVarQueryParameter['apVersion'] = apVersion;
                    }
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(apStateUpdate, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Set the preferred name of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointSetPreferName: function (id, partId, apVersion, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('accessPointSetPreferName', 'id', id);
                    // verify required parameter 'partId' is not null or undefined
                    common_1.assertParamExists('accessPointSetPreferName', 'partId', partId);
                    localVarPath = "/accesspoint/{id}/part/{partId}/prefer-name"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)))
                        .replace("{" + "partId" + "}", encodeURIComponent(String(partId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (apVersion !== undefined) {
                        localVarQueryParameter['apVersion'] = apVersion;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Set the preferred name of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of revision
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointSetPreferNameRevision: function (id, partId, apVersion, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('accessPointSetPreferNameRevision', 'id', id);
                    // verify required parameter 'partId' is not null or undefined
                    common_1.assertParamExists('accessPointSetPreferNameRevision', 'partId', partId);
                    localVarPath = "/accesspoint/{id}/revision/part/{partId}/prefer-name"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)))
                        .replace("{" + "partId" + "}", encodeURIComponent(String(partId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (apVersion !== undefined) {
                        localVarQueryParameter['apVersion'] = apVersion;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Copying an archive entity
         * @param {string} id id of accesspoint
         * @param {CopyAccessPointDetail} copyAccessPointDetail additional copy options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyAccessPoint: function (id, copyAccessPointDetail, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('copyAccessPoint', 'id', id);
                    // verify required parameter 'copyAccessPointDetail' is not null or undefined
                    common_1.assertParamExists('copyAccessPoint', 'copyAccessPointDetail', copyAccessPointDetail);
                    localVarPath = "/accesspoints/{id}/copy"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(copyAccessPointDetail, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Vytvoření nové revize přístupového bodu
         * @param {number} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRevision: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('createRevision', 'id', id);
                    localVarPath = "/revision/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Zneplatnění přístupovych bodu
         * @param {DeleteAccessPointsDetail} [deleteAccessPointsDetail] Seznam přístupových bodů
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessPoints: function (deleteAccessPointsDetail, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/accesspoints";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(deleteAccessPointsDetail, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Smazání revize přístupového bodu
         * @param {number} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRevision: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('deleteRevision', 'id', id);
                    localVarPath = "/revision/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Vrátí seznam automaticky generovaných prvků popisu pro revizi
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevAutoitems: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('getRevAutoitems', 'id', id);
                    localVarPath = "/revision/{id}/autoitems"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Obnovení neplatné entity a návrat do původního stavu
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccessPoint: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('restoreAccessPoint', 'id', id);
                    localVarPath = "/accesspoints/{id}/restore"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Ověřování entity
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAccessPoint: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('validateAccessPoint', 'id', id);
                    localVarPath = "/accesspoints/{id}/validate"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * AccesspointsApi - functional programming interface
 * @export
 */
exports.AccesspointsApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.AccesspointsApiAxiosParamCreator(configuration);
    return {
        /**
         * Change state of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {ApStateUpdate} apStateUpdate data for change ApState
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointChangeState: function (id, apStateUpdate, apVersion, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.accessPointChangeState(id, apStateUpdate, apVersion, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Change state of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {RevStateChange} revStateChange data for change state of revision of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointChangeStateRevision: function (id, revStateChange, apVersion, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.accessPointChangeStateRevision(id, revStateChange, apVersion, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Invalidation of ApAccessPoint (delete/replace)
         * @param {string} id id of accesspoint
         * @param {DeleteAccessPointDetail} [deleteAccessPointDetail] addition for method of invalidation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeleteAccessPoint: function (id, deleteAccessPointDetail, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.accessPointDeleteAccessPoint(id, deleteAccessPointDetail, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete part of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeletePart: function (id, partId, apVersion, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.accessPointDeletePart(id, partId, apVersion, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete part of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of revision
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeleteRevisionPart: function (id, partId, apVersion, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.accessPointDeleteRevisionPart(id, partId, apVersion, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * List of automatically generated elements of the description
         * @param {string} id id of accesspoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointGetAutoitems: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.accessPointGetAutoitems(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Merge ApRevision & ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {ApStateUpdate} apStateUpdate data for change ApState
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointMergeRevision: function (id, apStateUpdate, apVersion, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.accessPointMergeRevision(id, apStateUpdate, apVersion, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Set the preferred name of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointSetPreferName: function (id, partId, apVersion, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.accessPointSetPreferName(id, partId, apVersion, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Set the preferred name of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of revision
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointSetPreferNameRevision: function (id, partId, apVersion, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.accessPointSetPreferNameRevision(id, partId, apVersion, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Copying an archive entity
         * @param {string} id id of accesspoint
         * @param {CopyAccessPointDetail} copyAccessPointDetail additional copy options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyAccessPoint: function (id, copyAccessPointDetail, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.copyAccessPoint(id, copyAccessPointDetail, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Vytvoření nové revize přístupového bodu
         * @param {number} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRevision: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createRevision(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Zneplatnění přístupovych bodu
         * @param {DeleteAccessPointsDetail} [deleteAccessPointsDetail] Seznam přístupových bodů
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessPoints: function (deleteAccessPointsDetail, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteAccessPoints(deleteAccessPointsDetail, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Smazání revize přístupového bodu
         * @param {number} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRevision: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteRevision(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Vrátí seznam automaticky generovaných prvků popisu pro revizi
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevAutoitems: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRevAutoitems(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Obnovení neplatné entity a návrat do původního stavu
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccessPoint: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.restoreAccessPoint(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Ověřování entity
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAccessPoint: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.validateAccessPoint(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * AccesspointsApi - factory interface
 * @export
 */
exports.AccesspointsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.AccesspointsApiFp(configuration);
    return {
        /**
         * Change state of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {ApStateUpdate} apStateUpdate data for change ApState
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointChangeState: function (id, apStateUpdate, apVersion, options) {
            return localVarFp.accessPointChangeState(id, apStateUpdate, apVersion, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Change state of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {RevStateChange} revStateChange data for change state of revision of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointChangeStateRevision: function (id, revStateChange, apVersion, options) {
            return localVarFp.accessPointChangeStateRevision(id, revStateChange, apVersion, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Invalidation of ApAccessPoint (delete/replace)
         * @param {string} id id of accesspoint
         * @param {DeleteAccessPointDetail} [deleteAccessPointDetail] addition for method of invalidation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeleteAccessPoint: function (id, deleteAccessPointDetail, options) {
            return localVarFp.accessPointDeleteAccessPoint(id, deleteAccessPointDetail, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete part of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeletePart: function (id, partId, apVersion, options) {
            return localVarFp.accessPointDeletePart(id, partId, apVersion, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete part of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of revision
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointDeleteRevisionPart: function (id, partId, apVersion, options) {
            return localVarFp.accessPointDeleteRevisionPart(id, partId, apVersion, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * List of automatically generated elements of the description
         * @param {string} id id of accesspoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointGetAutoitems: function (id, options) {
            return localVarFp.accessPointGetAutoitems(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Merge ApRevision & ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {ApStateUpdate} apStateUpdate data for change ApState
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointMergeRevision: function (id, apStateUpdate, apVersion, options) {
            return localVarFp.accessPointMergeRevision(id, apStateUpdate, apVersion, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Set the preferred name of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of ApAccessPoint
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointSetPreferName: function (id, partId, apVersion, options) {
            return localVarFp.accessPointSetPreferName(id, partId, apVersion, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Set the preferred name of revision of ApAccessPoint
         * @param {number} id id of accesspoint
         * @param {number} partId partId of revision
         * @param {number} [apVersion] actual version of ApAccessPoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accessPointSetPreferNameRevision: function (id, partId, apVersion, options) {
            return localVarFp.accessPointSetPreferNameRevision(id, partId, apVersion, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Copying an archive entity
         * @param {string} id id of accesspoint
         * @param {CopyAccessPointDetail} copyAccessPointDetail additional copy options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyAccessPoint: function (id, copyAccessPointDetail, options) {
            return localVarFp.copyAccessPoint(id, copyAccessPointDetail, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Vytvoření nové revize přístupového bodu
         * @param {number} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRevision: function (id, options) {
            return localVarFp.createRevision(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Zneplatnění přístupovych bodu
         * @param {DeleteAccessPointsDetail} [deleteAccessPointsDetail] Seznam přístupových bodů
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccessPoints: function (deleteAccessPointsDetail, options) {
            return localVarFp.deleteAccessPoints(deleteAccessPointsDetail, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Smazání revize přístupového bodu
         * @param {number} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRevision: function (id, options) {
            return localVarFp.deleteRevision(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Vrátí seznam automaticky generovaných prvků popisu pro revizi
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRevAutoitems: function (id, options) {
            return localVarFp.getRevAutoitems(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Obnovení neplatné entity a návrat do původního stavu
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreAccessPoint: function (id, options) {
            return localVarFp.restoreAccessPoint(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Ověřování entity
         * @param {string} id Identifikátor archivní entity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateAccessPoint: function (id, options) {
            return localVarFp.validateAccessPoint(id, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * AccesspointsApi - object-oriented interface
 * @export
 * @class AccesspointsApi
 * @extends {BaseAPI}
 */
var AccesspointsApi = /** @class */ (function (_super) {
    __extends(AccesspointsApi, _super);
    function AccesspointsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Change state of ApAccessPoint
     * @param {number} id id of accesspoint
     * @param {ApStateUpdate} apStateUpdate data for change ApState
     * @param {number} [apVersion] actual version of ApAccessPoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.accessPointChangeState = function (id, apStateUpdate, apVersion, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).accessPointChangeState(id, apStateUpdate, apVersion, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Change state of revision of ApAccessPoint
     * @param {number} id id of accesspoint
     * @param {RevStateChange} revStateChange data for change state of revision of ApAccessPoint
     * @param {number} [apVersion] actual version of ApAccessPoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.accessPointChangeStateRevision = function (id, revStateChange, apVersion, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).accessPointChangeStateRevision(id, revStateChange, apVersion, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Invalidation of ApAccessPoint (delete/replace)
     * @param {string} id id of accesspoint
     * @param {DeleteAccessPointDetail} [deleteAccessPointDetail] addition for method of invalidation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.accessPointDeleteAccessPoint = function (id, deleteAccessPointDetail, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).accessPointDeleteAccessPoint(id, deleteAccessPointDetail, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete part of ApAccessPoint
     * @param {number} id id of accesspoint
     * @param {number} partId partId of ApAccessPoint
     * @param {number} [apVersion] actual version of ApAccessPoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.accessPointDeletePart = function (id, partId, apVersion, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).accessPointDeletePart(id, partId, apVersion, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete part of revision of ApAccessPoint
     * @param {number} id id of accesspoint
     * @param {number} partId partId of revision
     * @param {number} [apVersion] actual version of ApAccessPoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.accessPointDeleteRevisionPart = function (id, partId, apVersion, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).accessPointDeleteRevisionPart(id, partId, apVersion, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * List of automatically generated elements of the description
     * @param {string} id id of accesspoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.accessPointGetAutoitems = function (id, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).accessPointGetAutoitems(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Merge ApRevision & ApAccessPoint
     * @param {number} id id of accesspoint
     * @param {ApStateUpdate} apStateUpdate data for change ApState
     * @param {number} [apVersion] actual version of ApAccessPoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.accessPointMergeRevision = function (id, apStateUpdate, apVersion, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).accessPointMergeRevision(id, apStateUpdate, apVersion, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Set the preferred name of ApAccessPoint
     * @param {number} id id of accesspoint
     * @param {number} partId partId of ApAccessPoint
     * @param {number} [apVersion] actual version of ApAccessPoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.accessPointSetPreferName = function (id, partId, apVersion, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).accessPointSetPreferName(id, partId, apVersion, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Set the preferred name of revision of ApAccessPoint
     * @param {number} id id of accesspoint
     * @param {number} partId partId of revision
     * @param {number} [apVersion] actual version of ApAccessPoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.accessPointSetPreferNameRevision = function (id, partId, apVersion, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).accessPointSetPreferNameRevision(id, partId, apVersion, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Copying an archive entity
     * @param {string} id id of accesspoint
     * @param {CopyAccessPointDetail} copyAccessPointDetail additional copy options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.copyAccessPoint = function (id, copyAccessPointDetail, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).copyAccessPoint(id, copyAccessPointDetail, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Vytvoření nové revize přístupového bodu
     * @param {number} id Identifikátor archivní entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.createRevision = function (id, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).createRevision(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Zneplatnění přístupovych bodu
     * @param {DeleteAccessPointsDetail} [deleteAccessPointsDetail] Seznam přístupových bodů
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.deleteAccessPoints = function (deleteAccessPointsDetail, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).deleteAccessPoints(deleteAccessPointsDetail, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Smazání revize přístupového bodu
     * @param {number} id Identifikátor archivní entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.deleteRevision = function (id, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).deleteRevision(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Vrátí seznam automaticky generovaných prvků popisu pro revizi
     * @param {string} id Identifikátor archivní entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.getRevAutoitems = function (id, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).getRevAutoitems(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Obnovení neplatné entity a návrat do původního stavu
     * @param {string} id Identifikátor archivní entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.restoreAccessPoint = function (id, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).restoreAccessPoint(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Ověřování entity
     * @param {string} id Identifikátor archivní entity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccesspointsApi
     */
    AccesspointsApi.prototype.validateAccessPoint = function (id, options) {
        var _this = this;
        return exports.AccesspointsApiFp(this.configuration).validateAccessPoint(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AccesspointsApi;
}(base_1.BaseAPI));
exports.AccesspointsApi = AccesspointsApi;
/**
 * AdminApi - axios parameter creator
 * @export
 */
exports.AdminApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Copy user permissions from another user  Method will append all permissions to given user
         * @param {number} userId ID of target user
         * @param {AdminCopyPermissionParams} adminCopyPermissionParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCopyPermissions: function (userId, adminCopyPermissionParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'userId' is not null or undefined
                    common_1.assertParamExists('adminCopyPermissions', 'userId', userId);
                    // verify required parameter 'adminCopyPermissionParams' is not null or undefined
                    common_1.assertParamExists('adminCopyPermissions', 'adminCopyPermissionParams', adminCopyPermissionParams);
                    localVarPath = "/admin/user/{userId}/copy-permissions"
                        .replace("{" + "userId" + "}", encodeURIComponent(String(userId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(adminCopyPermissionParams, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Return basic statistical info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInfo: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/admin/info";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Return list of logged users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLoggedUsers: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/admin/info/logged-users";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Creating missing items in arr_cached_node
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSyncNodeCache: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/admin/admin/arrangement/missing-cached-nodes";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * AdminApi - functional programming interface
 * @export
 */
exports.AdminApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.AdminApiAxiosParamCreator(configuration);
    return {
        /**
         * Copy user permissions from another user  Method will append all permissions to given user
         * @param {number} userId ID of target user
         * @param {AdminCopyPermissionParams} adminCopyPermissionParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCopyPermissions: function (userId, adminCopyPermissionParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.adminCopyPermissions(userId, adminCopyPermissionParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Return basic statistical info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInfo: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.adminInfo(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Return list of logged users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLoggedUsers: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.adminLoggedUsers(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Creating missing items in arr_cached_node
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSyncNodeCache: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.adminSyncNodeCache(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * AdminApi - factory interface
 * @export
 */
exports.AdminApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.AdminApiFp(configuration);
    return {
        /**
         * Copy user permissions from another user  Method will append all permissions to given user
         * @param {number} userId ID of target user
         * @param {AdminCopyPermissionParams} adminCopyPermissionParams
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminCopyPermissions: function (userId, adminCopyPermissionParams, options) {
            return localVarFp.adminCopyPermissions(userId, adminCopyPermissionParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Return basic statistical info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminInfo: function (options) {
            return localVarFp.adminInfo(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Return list of logged users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminLoggedUsers: function (options) {
            return localVarFp.adminLoggedUsers(options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Creating missing items in arr_cached_node
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminSyncNodeCache: function (options) {
            return localVarFp.adminSyncNodeCache(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
var AdminApi = /** @class */ (function (_super) {
    __extends(AdminApi, _super);
    function AdminApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Copy user permissions from another user  Method will append all permissions to given user
     * @param {number} userId ID of target user
     * @param {AdminCopyPermissionParams} adminCopyPermissionParams
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    AdminApi.prototype.adminCopyPermissions = function (userId, adminCopyPermissionParams, options) {
        var _this = this;
        return exports.AdminApiFp(this.configuration).adminCopyPermissions(userId, adminCopyPermissionParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Return basic statistical info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    AdminApi.prototype.adminInfo = function (options) {
        var _this = this;
        return exports.AdminApiFp(this.configuration).adminInfo(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Return list of logged users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    AdminApi.prototype.adminLoggedUsers = function (options) {
        var _this = this;
        return exports.AdminApiFp(this.configuration).adminLoggedUsers(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Creating missing items in arr_cached_node
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    AdminApi.prototype.adminSyncNodeCache = function (options) {
        var _this = this;
        return exports.AdminApiFp(this.configuration).adminSyncNodeCache(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AdminApi;
}(base_1.BaseAPI));
exports.AdminApi = AdminApi;
/**
 * DaosApi - axios parameter creator
 * @export
 */
exports.DaosApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Změna scénáře napojení dao
         * @param {number} id Identifikátor dao
         * @param {string} body Nový scénář
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLinkScenario: function (id, body, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('changeLinkScenario', 'id', id);
                    // verify required parameter 'body' is not null or undefined
                    common_1.assertParamExists('changeLinkScenario', 'body', body);
                    localVarPath = "/daos/{id}/change-scenario"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(body, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * DaosApi - functional programming interface
 * @export
 */
exports.DaosApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.DaosApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Změna scénáře napojení dao
         * @param {number} id Identifikátor dao
         * @param {string} body Nový scénář
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLinkScenario: function (id, body, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.changeLinkScenario(id, body, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * DaosApi - factory interface
 * @export
 */
exports.DaosApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.DaosApiFp(configuration);
    return {
        /**
         *
         * @summary Změna scénáře napojení dao
         * @param {number} id Identifikátor dao
         * @param {string} body Nový scénář
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeLinkScenario: function (id, body, options) {
            return localVarFp.changeLinkScenario(id, body, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * DaosApi - object-oriented interface
 * @export
 * @class DaosApi
 * @extends {BaseAPI}
 */
var DaosApi = /** @class */ (function (_super) {
    __extends(DaosApi, _super);
    function DaosApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Změna scénáře napojení dao
     * @param {number} id Identifikátor dao
     * @param {string} body Nový scénář
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DaosApi
     */
    DaosApi.prototype.changeLinkScenario = function (id, body, options) {
        var _this = this;
        return exports.DaosApiFp(this.configuration).changeLinkScenario(id, body, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DaosApi;
}(base_1.BaseAPI));
exports.DaosApi = DaosApi;
/**
 * DefaultApi - axios parameter creator
 * @export
 */
exports.DefaultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daoRepositoryFsRepos: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/daorepository";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * DefaultApi - functional programming interface
 * @export
 */
exports.DefaultApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.DefaultApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daoRepositoryFsRepos: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.daoRepositoryFsRepos(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * DefaultApi - factory interface
 * @export
 */
exports.DefaultApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.DefaultApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        daoRepositoryFsRepos: function (options) {
            return localVarFp.daoRepositoryFsRepos(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.daoRepositoryFsRepos = function (options) {
        var _this = this;
        return exports.DefaultApiFp(this.configuration).daoRepositoryFsRepos(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DefaultApi;
}(base_1.BaseAPI));
exports.DefaultApi = DefaultApi;
/**
 * ExternalsystemsApi - axios parameter creator
 * @export
 */
exports.ExternalsystemsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Return list of all properties of external system
         * @param {number} [extSystemId] external system id
         * @param {number} [userId] user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemAllProperties: function (extSystemId, userId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/extsystem/property";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (extSystemId !== undefined) {
                        localVarQueryParameter['extSystemId'] = extSystemId;
                    }
                    if (userId !== undefined) {
                        localVarQueryParameter['userId'] = userId;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Delete one property of external system
         * @param {Array<number>} requestBody external system property id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemDeleteProperties: function (requestBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'requestBody' is not null or undefined
                    common_1.assertParamExists('externalSystemDeleteProperties', 'requestBody', requestBody);
                    localVarPath = "/extsystem/property";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Store one or more property of external system
         * @param {Array<ExtSystemProperty>} extSystemProperty request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemStoreProperties: function (extSystemProperty, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'extSystemProperty' is not null or undefined
                    common_1.assertParamExists('externalSystemStoreProperties', 'extSystemProperty', extSystemProperty);
                    localVarPath = "/extsystem/property";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(extSystemProperty, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * ExternalsystemsApi - functional programming interface
 * @export
 */
exports.ExternalsystemsApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ExternalsystemsApiAxiosParamCreator(configuration);
    return {
        /**
         * Return list of all properties of external system
         * @param {number} [extSystemId] external system id
         * @param {number} [userId] user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemAllProperties: function (extSystemId, userId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.externalSystemAllProperties(extSystemId, userId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Delete one property of external system
         * @param {Array<number>} requestBody external system property id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemDeleteProperties: function (requestBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.externalSystemDeleteProperties(requestBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Store one or more property of external system
         * @param {Array<ExtSystemProperty>} extSystemProperty request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemStoreProperties: function (extSystemProperty, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.externalSystemStoreProperties(extSystemProperty, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ExternalsystemsApi - factory interface
 * @export
 */
exports.ExternalsystemsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ExternalsystemsApiFp(configuration);
    return {
        /**
         * Return list of all properties of external system
         * @param {number} [extSystemId] external system id
         * @param {number} [userId] user id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemAllProperties: function (extSystemId, userId, options) {
            return localVarFp.externalSystemAllProperties(extSystemId, userId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Delete one property of external system
         * @param {Array<number>} requestBody external system property id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemDeleteProperties: function (requestBody, options) {
            return localVarFp.externalSystemDeleteProperties(requestBody, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Store one or more property of external system
         * @param {Array<ExtSystemProperty>} extSystemProperty request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        externalSystemStoreProperties: function (extSystemProperty, options) {
            return localVarFp.externalSystemStoreProperties(extSystemProperty, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ExternalsystemsApi - object-oriented interface
 * @export
 * @class ExternalsystemsApi
 * @extends {BaseAPI}
 */
var ExternalsystemsApi = /** @class */ (function (_super) {
    __extends(ExternalsystemsApi, _super);
    function ExternalsystemsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Return list of all properties of external system
     * @param {number} [extSystemId] external system id
     * @param {number} [userId] user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalsystemsApi
     */
    ExternalsystemsApi.prototype.externalSystemAllProperties = function (extSystemId, userId, options) {
        var _this = this;
        return exports.ExternalsystemsApiFp(this.configuration).externalSystemAllProperties(extSystemId, userId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Delete one property of external system
     * @param {Array<number>} requestBody external system property id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalsystemsApi
     */
    ExternalsystemsApi.prototype.externalSystemDeleteProperties = function (requestBody, options) {
        var _this = this;
        return exports.ExternalsystemsApiFp(this.configuration).externalSystemDeleteProperties(requestBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Store one or more property of external system
     * @param {Array<ExtSystemProperty>} extSystemProperty request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExternalsystemsApi
     */
    ExternalsystemsApi.prototype.externalSystemStoreProperties = function (extSystemProperty, options) {
        var _this = this;
        return exports.ExternalsystemsApiFp(this.configuration).externalSystemStoreProperties(extSystemProperty, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ExternalsystemsApi;
}(base_1.BaseAPI));
exports.ExternalsystemsApi = ExternalsystemsApi;
/**
 * FundsApi - axios parameter creator
 * @export
 */
exports.FundsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Založené nového archivního souboru
         * @param {CreateFund} [createFund] Data pro založení
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFund: function (createFund, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/fund";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(createFund, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Smazání seznamu hodnot strukturovaného datového typu
         * @param {number} id Identifikátor AS
         * @param {Array<number>} [requestBody] Seznam id hodnot strukturovaného datového typu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStructureData: function (id, requestBody, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('deleteStructureData', 'id', id);
                    localVarPath = "/fund/{id}/structuredObject"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Vyhledání archivních souborů
         * @param {string} [fulltext] Vyhledávaný výraz
         * @param {string} [institutionIdentifier] Identifikátor instituce (podpora pro kód instituce i UUID přístupového bodu)
         * @param {number} [max] Maximální počet výsledků na stránku
         * @param {number} [from] Od kolikátého záznamu se mají výsledky vracet (0 &#x3D; žádné se nepřeskočí)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFunds: function (fulltext, institutionIdentifier, max, from, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/fund";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (fulltext !== undefined) {
                        localVarQueryParameter['fulltext'] = fulltext;
                    }
                    if (institutionIdentifier !== undefined) {
                        localVarQueryParameter['institutionIdentifier'] = institutionIdentifier;
                    }
                    if (max !== undefined) {
                        localVarQueryParameter['max'] = max;
                    }
                    if (from !== undefined) {
                        localVarQueryParameter['from'] = from;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {number} nodeId Node ID
         * @param {string} [path]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsCreateDAOLink: function (fundId, fsrepoId, nodeId, path, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'fundId' is not null or undefined
                    common_1.assertParamExists('fundFsCreateDAOLink', 'fundId', fundId);
                    // verify required parameter 'fsrepoId' is not null or undefined
                    common_1.assertParamExists('fundFsCreateDAOLink', 'fsrepoId', fsrepoId);
                    // verify required parameter 'nodeId' is not null or undefined
                    common_1.assertParamExists('fundFsCreateDAOLink', 'nodeId', nodeId);
                    localVarPath = "/fund/{fundId}/fsrepo/{fsrepoId}/linkitem/{nodeId}"
                        .replace("{" + "fundId" + "}", encodeURIComponent(String(fundId)))
                        .replace("{" + "fsrepoId" + "}", encodeURIComponent(String(fsrepoId)))
                        .replace("{" + "nodeId" + "}", encodeURIComponent(String(nodeId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (path !== undefined) {
                        localVarQueryParameter['path'] = path;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {string} path file path in the repository
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepoItemData: function (fundId, fsrepoId, path, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'fundId' is not null or undefined
                    common_1.assertParamExists('fundFsRepoItemData', 'fundId', fundId);
                    // verify required parameter 'fsrepoId' is not null or undefined
                    common_1.assertParamExists('fundFsRepoItemData', 'fsrepoId', fsrepoId);
                    // verify required parameter 'path' is not null or undefined
                    common_1.assertParamExists('fundFsRepoItemData', 'path', path);
                    localVarPath = "/fund/{fundId}/fsrepo/{fsrepoId}/item-data"
                        .replace("{" + "fundId" + "}", encodeURIComponent(String(fundId)))
                        .replace("{" + "fsrepoId" + "}", encodeURIComponent(String(fsrepoId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (path !== undefined) {
                        localVarQueryParameter['path'] = path;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {'FOLDER' | 'FILE'} [filterType] Optional filter to return only folders or files. If not specified all items are returned
         * @param {string} [path] Optional path in the repository
         * @param {string} [lastKey] Optional parameter to return next batch of items. See FsItems for more details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepoItems: function (fundId, fsrepoId, filterType, path, lastKey, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'fundId' is not null or undefined
                    common_1.assertParamExists('fundFsRepoItems', 'fundId', fundId);
                    // verify required parameter 'fsrepoId' is not null or undefined
                    common_1.assertParamExists('fundFsRepoItems', 'fsrepoId', fsrepoId);
                    localVarPath = "/fund/{fundId}/fsrepo/{fsrepoId}/items"
                        .replace("{" + "fundId" + "}", encodeURIComponent(String(fundId)))
                        .replace("{" + "fsrepoId" + "}", encodeURIComponent(String(fsrepoId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (filterType !== undefined) {
                        localVarQueryParameter['filterType'] = filterType;
                    }
                    if (path !== undefined) {
                        localVarQueryParameter['path'] = path;
                    }
                    if (lastKey !== undefined) {
                        localVarQueryParameter['lastKey'] = lastKey;
                    }
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Return list of filesystem repositories
         * @param {number} fundId Fund ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepos: function (fundId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'fundId' is not null or undefined
                    common_1.assertParamExists('fundFsRepos', 'fundId', fundId);
                    localVarPath = "/fund/{fundId}/fsrepos"
                        .replace("{" + "fundId" + "}", encodeURIComponent(String(fundId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Return list of item type id & count
         * @param {number} fundId Fund ID
         * @param {number} fundVersionId Fund ID Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundUsedItemTypes: function (fundId, fundVersionId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'fundId' is not null or undefined
                    common_1.assertParamExists('fundUsedItemTypes', 'fundId', fundId);
                    // verify required parameter 'fundVersionId' is not null or undefined
                    common_1.assertParamExists('fundUsedItemTypes', 'fundVersionId', fundVersionId);
                    localVarPath = "/fund/{fundId}/usedItemtypes/{fundVersionId}"
                        .replace("{" + "fundId" + "}", encodeURIComponent(String(fundId)))
                        .replace("{" + "fundVersionId" + "}", encodeURIComponent(String(fundVersionId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Získání detailu AS
         * @param {string} id Identifikátor AS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFund: function (id, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('getFund', 'id', id);
                    localVarPath = "/fund/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Import additional data to the existing fund.
         * @param {string} id Fund ID. Might be ID or fund UUID.
         * @param {string} importType Import type. Only \\\&#39;CSV\\\&#39; is supported.
         * @param {any} dataFile File to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFundData: function (id, importType, dataFile, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('importFundData', 'id', id);
                    // verify required parameter 'importType' is not null or undefined
                    common_1.assertParamExists('importFundData', 'importType', importType);
                    // verify required parameter 'dataFile' is not null or undefined
                    common_1.assertParamExists('importFundData', 'dataFile', dataFile);
                    localVarPath = "/fund/{id}/import"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                    if (importType !== undefined) {
                        localVarFormParams.append('importType', importType);
                    }
                    if (dataFile !== undefined) {
                        localVarFormParams.append('dataFile', dataFile);
                    }
                    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams;
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Změna AS
         * @param {string} id Identifikátor AS
         * @param {UpdateFund} [updateFund] Data pro založení
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFund: function (id, updateFund, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'id' is not null or undefined
                    common_1.assertParamExists('updateFund', 'id', id);
                    localVarPath = "/fund/{id}"
                        .replace("{" + "id" + "}", encodeURIComponent(String(id)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(updateFund, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * FundsApi - functional programming interface
 * @export
 */
exports.FundsApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.FundsApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Založené nového archivního souboru
         * @param {CreateFund} [createFund] Data pro založení
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFund: function (createFund, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.createFund(createFund, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Smazání seznamu hodnot strukturovaného datového typu
         * @param {number} id Identifikátor AS
         * @param {Array<number>} [requestBody] Seznam id hodnot strukturovaného datového typu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStructureData: function (id, requestBody, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.deleteStructureData(id, requestBody, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Vyhledání archivních souborů
         * @param {string} [fulltext] Vyhledávaný výraz
         * @param {string} [institutionIdentifier] Identifikátor instituce (podpora pro kód instituce i UUID přístupového bodu)
         * @param {number} [max] Maximální počet výsledků na stránku
         * @param {number} [from] Od kolikátého záznamu se mají výsledky vracet (0 &#x3D; žádné se nepřeskočí)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFunds: function (fulltext, institutionIdentifier, max, from, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.findFunds(fulltext, institutionIdentifier, max, from, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {number} nodeId Node ID
         * @param {string} [path]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsCreateDAOLink: function (fundId, fsrepoId, nodeId, path, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.fundFsCreateDAOLink(fundId, fsrepoId, nodeId, path, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {string} path file path in the repository
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepoItemData: function (fundId, fsrepoId, path, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.fundFsRepoItemData(fundId, fsrepoId, path, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {'FOLDER' | 'FILE'} [filterType] Optional filter to return only folders or files. If not specified all items are returned
         * @param {string} [path] Optional path in the repository
         * @param {string} [lastKey] Optional parameter to return next batch of items. See FsItems for more details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepoItems: function (fundId, fsrepoId, filterType, path, lastKey, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.fundFsRepoItems(fundId, fsrepoId, filterType, path, lastKey, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Return list of filesystem repositories
         * @param {number} fundId Fund ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepos: function (fundId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.fundFsRepos(fundId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Return list of item type id & count
         * @param {number} fundId Fund ID
         * @param {number} fundVersionId Fund ID Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundUsedItemTypes: function (fundId, fundVersionId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.fundUsedItemTypes(fundId, fundVersionId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Získání detailu AS
         * @param {string} id Identifikátor AS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFund: function (id, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getFund(id, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Import additional data to the existing fund.
         * @param {string} id Fund ID. Might be ID or fund UUID.
         * @param {string} importType Import type. Only \\\&#39;CSV\\\&#39; is supported.
         * @param {any} dataFile File to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFundData: function (id, importType, dataFile, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.importFundData(id, importType, dataFile, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Změna AS
         * @param {string} id Identifikátor AS
         * @param {UpdateFund} [updateFund] Data pro založení
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFund: function (id, updateFund, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateFund(id, updateFund, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * FundsApi - factory interface
 * @export
 */
exports.FundsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.FundsApiFp(configuration);
    return {
        /**
         *
         * @summary Založené nového archivního souboru
         * @param {CreateFund} [createFund] Data pro založení
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFund: function (createFund, options) {
            return localVarFp.createFund(createFund, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Smazání seznamu hodnot strukturovaného datového typu
         * @param {number} id Identifikátor AS
         * @param {Array<number>} [requestBody] Seznam id hodnot strukturovaného datového typu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStructureData: function (id, requestBody, options) {
            return localVarFp.deleteStructureData(id, requestBody, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Vyhledání archivních souborů
         * @param {string} [fulltext] Vyhledávaný výraz
         * @param {string} [institutionIdentifier] Identifikátor instituce (podpora pro kód instituce i UUID přístupového bodu)
         * @param {number} [max] Maximální počet výsledků na stránku
         * @param {number} [from] Od kolikátého záznamu se mají výsledky vracet (0 &#x3D; žádné se nepřeskočí)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findFunds: function (fulltext, institutionIdentifier, max, from, options) {
            return localVarFp.findFunds(fulltext, institutionIdentifier, max, from, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {number} nodeId Node ID
         * @param {string} [path]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsCreateDAOLink: function (fundId, fsrepoId, nodeId, path, options) {
            return localVarFp.fundFsCreateDAOLink(fundId, fsrepoId, nodeId, path, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {string} path file path in the repository
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepoItemData: function (fundId, fsrepoId, path, options) {
            return localVarFp.fundFsRepoItemData(fundId, fsrepoId, path, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {number} fundId Fund ID
         * @param {number} fsrepoId FileSystem repository ID
         * @param {'FOLDER' | 'FILE'} [filterType] Optional filter to return only folders or files. If not specified all items are returned
         * @param {string} [path] Optional path in the repository
         * @param {string} [lastKey] Optional parameter to return next batch of items. See FsItems for more details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepoItems: function (fundId, fsrepoId, filterType, path, lastKey, options) {
            return localVarFp.fundFsRepoItems(fundId, fsrepoId, filterType, path, lastKey, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Return list of filesystem repositories
         * @param {number} fundId Fund ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundFsRepos: function (fundId, options) {
            return localVarFp.fundFsRepos(fundId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Return list of item type id & count
         * @param {number} fundId Fund ID
         * @param {number} fundVersionId Fund ID Version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fundUsedItemTypes: function (fundId, fundVersionId, options) {
            return localVarFp.fundUsedItemTypes(fundId, fundVersionId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Získání detailu AS
         * @param {string} id Identifikátor AS
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFund: function (id, options) {
            return localVarFp.getFund(id, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Import additional data to the existing fund.
         * @param {string} id Fund ID. Might be ID or fund UUID.
         * @param {string} importType Import type. Only \\\&#39;CSV\\\&#39; is supported.
         * @param {any} dataFile File to upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importFundData: function (id, importType, dataFile, options) {
            return localVarFp.importFundData(id, importType, dataFile, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Změna AS
         * @param {string} id Identifikátor AS
         * @param {UpdateFund} [updateFund] Data pro založení
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFund: function (id, updateFund, options) {
            return localVarFp.updateFund(id, updateFund, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * FundsApi - object-oriented interface
 * @export
 * @class FundsApi
 * @extends {BaseAPI}
 */
var FundsApi = /** @class */ (function (_super) {
    __extends(FundsApi, _super);
    function FundsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Založené nového archivního souboru
     * @param {CreateFund} [createFund] Data pro založení
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    FundsApi.prototype.createFund = function (createFund, options) {
        var _this = this;
        return exports.FundsApiFp(this.configuration).createFund(createFund, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Smazání seznamu hodnot strukturovaného datového typu
     * @param {number} id Identifikátor AS
     * @param {Array<number>} [requestBody] Seznam id hodnot strukturovaného datového typu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    FundsApi.prototype.deleteStructureData = function (id, requestBody, options) {
        var _this = this;
        return exports.FundsApiFp(this.configuration).deleteStructureData(id, requestBody, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Vyhledání archivních souborů
     * @param {string} [fulltext] Vyhledávaný výraz
     * @param {string} [institutionIdentifier] Identifikátor instituce (podpora pro kód instituce i UUID přístupového bodu)
     * @param {number} [max] Maximální počet výsledků na stránku
     * @param {number} [from] Od kolikátého záznamu se mají výsledky vracet (0 &#x3D; žádné se nepřeskočí)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    FundsApi.prototype.findFunds = function (fulltext, institutionIdentifier, max, from, options) {
        var _this = this;
        return exports.FundsApiFp(this.configuration).findFunds(fulltext, institutionIdentifier, max, from, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} fundId Fund ID
     * @param {number} fsrepoId FileSystem repository ID
     * @param {number} nodeId Node ID
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    FundsApi.prototype.fundFsCreateDAOLink = function (fundId, fsrepoId, nodeId, path, options) {
        var _this = this;
        return exports.FundsApiFp(this.configuration).fundFsCreateDAOLink(fundId, fsrepoId, nodeId, path, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} fundId Fund ID
     * @param {number} fsrepoId FileSystem repository ID
     * @param {string} path file path in the repository
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    FundsApi.prototype.fundFsRepoItemData = function (fundId, fsrepoId, path, options) {
        var _this = this;
        return exports.FundsApiFp(this.configuration).fundFsRepoItemData(fundId, fsrepoId, path, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {number} fundId Fund ID
     * @param {number} fsrepoId FileSystem repository ID
     * @param {'FOLDER' | 'FILE'} [filterType] Optional filter to return only folders or files. If not specified all items are returned
     * @param {string} [path] Optional path in the repository
     * @param {string} [lastKey] Optional parameter to return next batch of items. See FsItems for more details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    FundsApi.prototype.fundFsRepoItems = function (fundId, fsrepoId, filterType, path, lastKey, options) {
        var _this = this;
        return exports.FundsApiFp(this.configuration).fundFsRepoItems(fundId, fsrepoId, filterType, path, lastKey, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Return list of filesystem repositories
     * @param {number} fundId Fund ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    FundsApi.prototype.fundFsRepos = function (fundId, options) {
        var _this = this;
        return exports.FundsApiFp(this.configuration).fundFsRepos(fundId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Return list of item type id & count
     * @param {number} fundId Fund ID
     * @param {number} fundVersionId Fund ID Version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    FundsApi.prototype.fundUsedItemTypes = function (fundId, fundVersionId, options) {
        var _this = this;
        return exports.FundsApiFp(this.configuration).fundUsedItemTypes(fundId, fundVersionId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Získání detailu AS
     * @param {string} id Identifikátor AS
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    FundsApi.prototype.getFund = function (id, options) {
        var _this = this;
        return exports.FundsApiFp(this.configuration).getFund(id, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Import additional data to the existing fund.
     * @param {string} id Fund ID. Might be ID or fund UUID.
     * @param {string} importType Import type. Only \\\&#39;CSV\\\&#39; is supported.
     * @param {any} dataFile File to upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    FundsApi.prototype.importFundData = function (id, importType, dataFile, options) {
        var _this = this;
        return exports.FundsApiFp(this.configuration).importFundData(id, importType, dataFile, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Změna AS
     * @param {string} id Identifikátor AS
     * @param {UpdateFund} [updateFund] Data pro založení
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundsApi
     */
    FundsApi.prototype.updateFund = function (id, updateFund, options) {
        var _this = this;
        return exports.FundsApiFp(this.configuration).updateFund(id, updateFund, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return FundsApi;
}(base_1.BaseAPI));
exports.FundsApi = FundsApi;
/**
 * IoApi - axios parameter creator
 * @export
 */
exports.IoApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         * Create export file of funds or access points
         * @param {ExportParams} exportParams Export request parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioExportRequest: function (exportParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'exportParams' is not null or undefined
                    common_1.assertParamExists('ioExportRequest', 'exportParams', exportParams);
                    localVarPath = "/io/export";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(exportParams, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Getting the generated file by request id
         * @param {number} requestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioGetExportFile: function (requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'requestId' is not null or undefined
                    common_1.assertParamExists('ioGetExportFile', 'requestId', requestId);
                    localVarPath = "/io/file/{requestId}"
                        .replace("{" + "requestId" + "}", encodeURIComponent(String(requestId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Getting the export status by request id
         * @param {number} requestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioGetExportStatus: function (requestId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'requestId' is not null or undefined
                    common_1.assertParamExists('ioGetExportStatus', 'requestId', requestId);
                    localVarPath = "/io/export-status/{requestId}"
                        .replace("{" + "requestId" + "}", encodeURIComponent(String(requestId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * IoApi - functional programming interface
 * @export
 */
exports.IoApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.IoApiAxiosParamCreator(configuration);
    return {
        /**
         * Create export file of funds or access points
         * @param {ExportParams} exportParams Export request parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioExportRequest: function (exportParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.ioExportRequest(exportParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Getting the generated file by request id
         * @param {number} requestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioGetExportFile: function (requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.ioGetExportFile(requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Getting the export status by request id
         * @param {number} requestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioGetExportStatus: function (requestId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.ioGetExportStatus(requestId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * IoApi - factory interface
 * @export
 */
exports.IoApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.IoApiFp(configuration);
    return {
        /**
         * Create export file of funds or access points
         * @param {ExportParams} exportParams Export request parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioExportRequest: function (exportParams, options) {
            return localVarFp.ioExportRequest(exportParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Getting the generated file by request id
         * @param {number} requestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioGetExportFile: function (requestId, options) {
            return localVarFp.ioGetExportFile(requestId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Getting the export status by request id
         * @param {number} requestId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ioGetExportStatus: function (requestId, options) {
            return localVarFp.ioGetExportStatus(requestId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * IoApi - object-oriented interface
 * @export
 * @class IoApi
 * @extends {BaseAPI}
 */
var IoApi = /** @class */ (function (_super) {
    __extends(IoApi, _super);
    function IoApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Create export file of funds or access points
     * @param {ExportParams} exportParams Export request parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IoApi
     */
    IoApi.prototype.ioExportRequest = function (exportParams, options) {
        var _this = this;
        return exports.IoApiFp(this.configuration).ioExportRequest(exportParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Getting the generated file by request id
     * @param {number} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IoApi
     */
    IoApi.prototype.ioGetExportFile = function (requestId, options) {
        var _this = this;
        return exports.IoApiFp(this.configuration).ioGetExportFile(requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Getting the export status by request id
     * @param {number} requestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IoApi
     */
    IoApi.prototype.ioGetExportStatus = function (requestId, options) {
        var _this = this;
        return exports.IoApiFp(this.configuration).ioGetExportStatus(requestId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return IoApi;
}(base_1.BaseAPI));
exports.IoApi = IoApi;
/**
 * SearchApi - axios parameter creator
 * @export
 */
exports.SearchApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary searches for entity
         * @param {SearchParams} [searchParams] search parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArchDesc: function (searchParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/search-arr";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(searchParams, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary searches for entity
         * @param {SearchParams} [searchParams] search parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEntity: function (searchParams, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/search-ap";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(searchParams, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * SearchApi - functional programming interface
 * @export
 */
exports.SearchApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.SearchApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary searches for entity
         * @param {SearchParams} [searchParams] search parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArchDesc: function (searchParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchArchDesc(searchParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary searches for entity
         * @param {SearchParams} [searchParams] search parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEntity: function (searchParams, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.searchEntity(searchParams, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * SearchApi - factory interface
 * @export
 */
exports.SearchApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.SearchApiFp(configuration);
    return {
        /**
         *
         * @summary searches for entity
         * @param {SearchParams} [searchParams] search parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchArchDesc: function (searchParams, options) {
            return localVarFp.searchArchDesc(searchParams, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary searches for entity
         * @param {SearchParams} [searchParams] search parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchEntity: function (searchParams, options) {
            return localVarFp.searchEntity(searchParams, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
var SearchApi = /** @class */ (function (_super) {
    __extends(SearchApi, _super);
    function SearchApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary searches for entity
     * @param {SearchParams} [searchParams] search parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    SearchApi.prototype.searchArchDesc = function (searchParams, options) {
        var _this = this;
        return exports.SearchApiFp(this.configuration).searchArchDesc(searchParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary searches for entity
     * @param {SearchParams} [searchParams] search parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    SearchApi.prototype.searchEntity = function (searchParams, options) {
        var _this = this;
        return exports.SearchApiFp(this.configuration).searchEntity(searchParams, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return SearchApi;
}(base_1.BaseAPI));
exports.SearchApi = SearchApi;
